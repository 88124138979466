import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../../components/Layout'

const SuccessPage = () => {
  return (
    <Layout>
      <section className='hero is-primary is-large'>
        <div className='hero-body has-text-centered'>
          <Link to='/'>
            <h1 className='title has-text-centered'>
              Success
            </h1>
            <h2 class='subtitle has-text-centered'>Thank you for reaching out!</h2>
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessPage
